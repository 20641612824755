import React, { useMemo } from 'react';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { PhantomWalletAdapter, SolflareWalletAdapter } from '@solana/wallet-adapter-wallets';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import { clusterApiUrl } from '@solana/web3.js';
import WalletConnection from './components/WalletConnection';
import SwapInterface from './components/SwapInterface';
import BurnTokensForm from './components/BurnTokensForm';
import './App.css';

// Default styles that can be overridden by your app
import '@solana/wallet-adapter-react-ui/styles.css';

function App() {
  const network = WalletAdapterNetwork.Mainnet;
  const endpoint = useMemo(() => "https://light-nameless-flower.solana-mainnet.quiknode.pro/7177aacc39760c417973e65a860d7ddcdb904a09/", []);
  const wallets = useMemo(
    () => [new PhantomWalletAdapter(), new SolflareWalletAdapter()],
    []
  );

  const handleReload = () => {
    window.location.reload();
  };

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          <div className="App">
            <header className="App-header">
              <img src="/silver.png" alt="FinAnon Logo" />
            </header>
            <WalletConnection />
            <main className="App-main">
              <div className="interaction-container">
               <h2>Redefining Financial Analysis with AI 🔍</h2>
                <p className="subtext">
                  FinAnon retrieves, anonymizes, and processes balance sheets from NYSE traded companies using the process outlined by this{' '}
                  <a href="https://bfi.uchicago.edu/wp-content/uploads/2024/05/BFI_WP_2024-65.pdf" target="_blank" rel="noopener noreferrer">
                    U of Chicago paper
                  </a>{' '}
                  that shows finanical analysis and prediction produced by GPT to be more accurate than those made by humans.
                </p>
                <SwapInterface />
                <BurnTokensForm />
                <button onClick={handleReload} className="reload-button">Reload Page</button>
              </div>
              <div className="footer-container">
                <h3>Links:</h3>
                <p>
                  <a href="https://x.com/FinAnonOfficial" target="_blank" rel="noopener noreferrer">
                    Twitter 🐦‍⬛
                  </a>
                </p>
              </div>
            </main>
          </div>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
}

export default App;
