// components/WalletConnection.js
import React from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';

function WalletConnection() {
  const { connected, publicKey } = useWallet();

  return (
    <div className="wallet-connection">
      {!connected ? (
        <>
          <span>You haven't connected a wallet.</span>
          <WalletMultiButton className="connect-wallet-button">Connect Wallet</WalletMultiButton>
        </>
      ) : (
        <>
          <span>Connected: {publicKey.toBase58().slice(0, 4)}...{publicKey.toBase58().slice(-4)}</span>
          <WalletMultiButton className="disconnect-wallet-button">Disconnect</WalletMultiButton>
        </>
      )}
    </div>
  );
}

export default WalletConnection;
