import React, { useState } from 'react';
import { useWallet, useConnection } from '@solana/wallet-adapter-react';
import { Program, AnchorProvider } from '@project-serum/anchor';
import idl from '../idl/token_swap.json';
import { PublicKey } from '@solana/web3.js';
import { TOKEN_PROGRAM_ID, ASSOCIATED_TOKEN_PROGRAM_ID } from '@solana/spl-token';
import Modal from './Modal'; 

const programID = new PublicKey('2MDnmWds28cEsihULSMJ7k7jqdj7MeusANPD5k1rRpaJ');
const MINT_ADDRESS = new PublicKey('7Ws5ewZKftGbnoQQT3zxxJesZdm3UVUmvQAwtY7gSGKb');

function BurnTokensForm() {
  const wallet = useWallet();
  const { connection } = useConnection();
  const [symbol, setSymbol] = useState('');
  const [isBurned, setIsBurned] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [analysisResult, setAnalysisResult] = useState('');

  const handleBurn = async () => {
    try {
      const provider = new AnchorProvider(connection, wallet, {});
      const program = new Program(idl, programID, provider);

      const [userTokenAccount] = await PublicKey.findProgramAddress(
        [
          wallet.publicKey.toBuffer(),
          TOKEN_PROGRAM_ID.toBuffer(),
          MINT_ADDRESS.toBuffer(),
        ],
        ASSOCIATED_TOKEN_PROGRAM_ID
      );

      await program.methods.burnTokens()
        .accounts({
          user: wallet.publicKey,
          mint: MINT_ADDRESS,
          userTokenAccount: userTokenAccount,
          tokenProgram: TOKEN_PROGRAM_ID,
        })
        .rpc();

      console.log('Burn successful!');
      setIsBurned(true);
    } catch (error) {
      console.error('Burn failed:', error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const response = await fetch('/api/analyze', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ symbol }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Symbol submitted successfully');
        setAnalysisResult(data.result);
        setIsSubmitted(true);
      } else {
        console.error('Failed to submit symbol');
      }
    } catch (error) {
      console.error('Error submitting symbol:', error);
    }
    setIsLoading(false);
  };

  return (
    <div>
      {!isBurned ? (
        <button onClick={handleBurn} disabled={!wallet.connected}>
          Burn 1 Token To Analyze Ticker
        </button>
      ) : !isSubmitted ? (
        <form onSubmit={handleSubmit}>
          <label htmlFor="symbol">Fetch & Process Balance Sheets:</label>
          <input
            type="text"
            id="symbol"
            value={symbol}
            onChange={(e) => setSymbol(e.target.value.toUpperCase())}
            maxLength={5}
            required
            placeholder="enter ticker"
          />
          <button type="submit">Submit</button>
        </form>
      ) : (
        <div>
          <p>Thank you for your submission!</p>
          <div dangerouslySetInnerHTML={{ __html: analysisResult }} />
        </div>
      )}
      {isLoading && (
        <Modal isOpen={isLoading} onClose={() => setIsLoading(false)}>
          <h2>Processing</h2>
          <p>Your submission is being processed. Please wait...</p>
        </Modal>
      )}
    </div>
  );
}

export default BurnTokensForm;

