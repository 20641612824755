import React, { useState } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { useConnection } from '@solana/wallet-adapter-react';
import { PublicKey, SystemProgram, LAMPORTS_PER_SOL } from '@solana/web3.js';
import { Program, AnchorProvider, BN } from '@project-serum/anchor';
import idl from '../idl/token_swap.json';
import { TOKEN_PROGRAM_ID, ASSOCIATED_TOKEN_PROGRAM_ID } from '@solana/spl-token';
import './SwapInterface.css';
import Modal from './Modal';

const programID = new PublicKey('2MDnmWds28cEsihULSMJ7k7jqdj7MeusANPD5k1rRpaJ');
const MINT_ADDRESS = new PublicKey("7Ws5ewZKftGbnoQQT3zxxJesZdm3UVUmvQAwtY7gSGKb");

function SwapInterface() {
  const wallet = useWallet();
  const { connection } = useConnection();
  const [isLoading, setIsLoading] = useState(false);
  const [amount, setAmount] = useState(0.5);
  const [modalMessage, setModalMessage] = useState('');
  const [modalHeader, setModalHeader] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getProgram = () => {
    const provider = new AnchorProvider(connection, wallet, {});
    return new Program(idl, programID, provider);
  };

  const handleSwap = async () => {
    if (!wallet.connected) {
      setModalHeader('Error');
      setModalMessage('Please connect your wallet first!');
      setIsModalOpen(true);
      return;
    }

    setIsLoading(true);
    try {
      const program = getProgram();

      // Check user's SOL balance
      const balance = await connection.getBalance(wallet.publicKey);
      const swapAmount = new BN(amount * LAMPORTS_PER_SOL);
      if (balance < swapAmount.toNumber()) {
        setModalHeader('Error');
        setModalMessage('Insufficient SOL balance');
        setIsModalOpen(true);
        setIsLoading(false);
        return;
      }

      const [vaultPDA] = await PublicKey.findProgramAddress(
        [Buffer.from("vault")],
        programID
      );

      const [vaultTokenAccount] = await PublicKey.findProgramAddress(
        [
          vaultPDA.toBuffer(),
          TOKEN_PROGRAM_ID.toBuffer(),
          MINT_ADDRESS.toBuffer(),
        ],
        ASSOCIATED_TOKEN_PROGRAM_ID
      );

      const [userTokenAccount] = await PublicKey.findProgramAddress(
        [
          wallet.publicKey.toBuffer(),
          TOKEN_PROGRAM_ID.toBuffer(),
          MINT_ADDRESS.toBuffer(),
        ],
        ASSOCIATED_TOKEN_PROGRAM_ID
      );

      await program.methods.swapSolForTokens(swapAmount)
        .accounts({
          user: wallet.publicKey,
          vault: vaultPDA,
          mint: MINT_ADDRESS,
          vaultTokenAccount: vaultTokenAccount,
          userTokenAccount: userTokenAccount,
          tokenProgram: TOKEN_PROGRAM_ID,
          associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
          systemProgram: SystemProgram.programId,
        })
        .rpc();

      setModalHeader('Swap Successful!');
      setModalMessage(`You've swapped ${amount} SOL for tokens.`);
      setIsModalOpen(true);
    } catch (error) {
      console.error('Error:', error);
      setModalHeader('Error');
      setModalMessage(`Swap failed: ${error.message}`);
      setIsModalOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="swap-interface">
      <div className="input-container">
        <input 
          type="text" 
          value={amount} 
          onChange={(e) => {
          const value = e.target.value;
          // Regex to allow only numbers and a single decimal point
          if (/^\d*\.?\d*$/.test(value)) {
            setAmount(value);
          }
        }} 
        min="0.01" 
        step="0.01" 
        disabled={isLoading}
        className="amount-input"
      />
      </div>
      <button 
        onClick={handleSwap} 
        disabled={!wallet.connected || isLoading}
        className="swap-button"
      >
        {isLoading ? 'Processing...' : `Swap ${amount} SOL for Tokens`}
      </button>
      
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <h2>{modalHeader}</h2>
        <p>{modalMessage}</p>
      </Modal>
    </div>
  );
}

export default SwapInterface;
